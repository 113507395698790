<template>
  <div class="newscl">
    <!-- header组件     -->
    <vheader :navId="navId"> </vheader>
    <!-- header组件     -->

    <!-- banner组件 -->
    <div class="banner-box">
      <div class="banner-img"></div>
      <div class="banner-nav">
        <div class="banner-nav-content">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <span><i class="el-icon-location"></i>当前位置：</span>
            <el-breadcrumb-item :to="{ path: 'index' }" title="业务入口">网站首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: 'mediaCoverage' }" title="媒体报道">媒体报道</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: 'newscl1' }" title="新闻详情">新闻详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <!-- banner组件 -->
    <div class="newscl_w">
      <div class="newsContentBoxLeft">
        <h1 class="newsContentBoxLeftTitle" id="title">恒安康泰专业护士陪诊开放单项预约</h1>
        <div id="frameContent">
          <!-- <p style="line-height: 1.75em; text-indent: 0em; text-align: center;"><img
              src="../../assets/img/mediaCoverage/1629537263.jpg" title="1629537263.jpg" alt="1629537263.jpg"
              width="615" height="334"></p> -->
          <p style="text-indent: 0em; line-height: 2em;"><span style="text-indent: 0em;"><br></span></p>
          <p style="text-indent: 0em; line-height: 2em;"><span
              style="text-indent: 0em;">恒安康泰专业护理团队，持证护士专业人员为患者在就诊期间的陪诊、陪检服务，自2021年9月1日起，开启单项预约了。</span><br></p>
          <p style="text-indent: 0em; line-height: 2em;"><br></p>
          <p style="text-indent: 0em; line-height: 2em;">会员用户与非会员用户均可在恒安康泰官方平台提交专业护士就医辅助申请，会员用户免预约费。</p>
          <p style="text-indent: 0em; line-height: 2em;"><br></p>
          <p style="text-indent: 0em; line-height: 2em;">
            陪诊、陪检服务是患者在就医期间放心安心的保障。恒安康泰专业护士就医辅助服务包括但不仅限于：医院迎接、门诊取号、挂号缴费、诊前咨询、就诊指导、诊室随诊、医嘱解读、报告分析等就医期间的专业护士陪同服务。</p>
          <p style="text-indent: 0em; line-height: 2em;"><br></p>
          <p style="text-indent: 0em; line-height: 2em;">可服务患者的城市：北京、天津、内蒙古呼和浩特。</p>
          <p style="text-indent: 0em; line-height: 2em;"><br></p>
          <p style="text-indent: 0em; line-height: 2em;">
            我们的专业护理团队均为经过专业系统的医疗护理培训，具有丰富护理经验的，持有护士资格证的专业从业人员。深悉医院环境、了解医院最新动态、洞悉患者需求、掌握医护知识，具有较高的医护综合素养和人文关怀素质，为您提供一流的舒适、减压、专业的医疗护理及辅助就诊服务。
          </p>
          <p style="text-indent: 0em; line-height: 2em;"><br></p>
          <p style="text-indent: 0em; line-height: 2em;">
            陪诊陪检专项人员肩负着引导患者如何更好、更及时就医的重任，是打造“以患者为中心”理念下就医环境的重要参与者。恒安康泰对全体用户就该专项服务开通申请通道，意在让更多用户在就医期间减少时间和精力上不必要的损耗，解决因医院不熟、诊室难找、报告难懂、人员嘈杂而造成患者身心负担的问题，提升患者就医体验，协助患者完成无忧诊疗。
          </p>
          <p style="text-indent: 0em; line-height: 2em;"><br></p>
          <p style="text-indent: 0em; line-height: 2em;">恒安康泰，陪伴，在最需要的时刻。</p>
          <p><br></p>
          <p><br></p>
          <p style="text-align: left;"><span style="font-size: 18px;"><strong>预约方式:</strong></span></p>
          <table align="center" style="width:100%">
            <tbody>
              <tr class="ue-table-interlace-color-single firstRow">
                <td width="183" valign="top" style="word-break: break-all; border-width: 1px; border-style: solid;"
                  align="left">
                  <p style="text-indent: 2em;">预约热线</p>
                </td>
                <td width="432" valign="middle" style="word-break: break-all; border-width: 1px; border-style: solid;"
                  align="center">&nbsp; &nbsp; &nbsp;4001-089-289</td>
              </tr>
              <tr class="ue-table-interlace-color-double">
                <td width="183" valign="top" style="word-break: break-all; border-width: 1px; border-style: solid;"
                  align="left">
                  <p style="text-indent: 2em;">官网网址</p>
                </td>
                <td width="432" valign="middle" style="word-break: break-all; border-width: 1px; border-style: solid;"
                  align="center">&nbsp; &nbsp; &nbsp;www.henganmedicare.com</td>
              </tr>
              <tr class="ue-table-interlace-color-single">
                <td width="183" valign="top" style="word-break: break-all; border-width: 1px; border-style: solid;"
                  align="left">
                  <p style="text-indent: 2em;">电话预约</p>
                </td>
                <td width="432" valign="middle" style="word-break: break-all; border-width: 1px; border-style: solid;"
                  align="center">&nbsp; &nbsp; &nbsp;185 1900 7576</td>
              </tr>
              <tr class="ue-table-interlace-color-double">
                <td width="183" valign="top" style="word-break: break-all; border-width: 1px; border-style: solid;"
                  align="left">
                  <p style="text-indent: 2em;">微信客服</p>
                </td>
                <td width="432" valign="middle" style="word-break: break-all; border-width: 1px; border-style: solid;"
                  align="center">&nbsp; &nbsp; &nbsp;3189594</td>
              </tr>
              <tr class="ue-table-interlace-color-single">
                <td width="183" valign="middle" style="word-break: break-all; border-width: 1px; border-style: solid;"
                  align="left">
                  <p style="text-indent: 0em;">&nbsp; &nbsp; &nbsp;&nbsp;</p>
                  <p style="text-indent: 0em;">&nbsp; &nbsp; &nbsp;微信公众号</p>
                </td>
                <td width="432" valign="middle" style="word-break: break-all; border-width: 1px; border-style: solid;"
                  align="center">
                  <p>恒安康泰医疗健康</p>
                  <p><img src="../../assets/img/mediaCoverage/1632297864.jpg" title="1629540786.jpg"
                      alt="1629540786.jpg" width="150" height="150"></p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
          <p style="display: none;"><br></p>
        </div>
      </div>
      <div class="listNewsRight">
        <h2 class="stateNewsRighth2">健康热点资讯</h2>
        <hr class="stateNewsRight_h2_hr" />
        <h3 class="stateNewsRighth3">
          <a href="" title="国务新闻">国务新闻</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[0]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
        <h2 class="stateNewsRighth3">恒安图文</h2>
        <hr class="stateNewsRight_h3_hr" />
        <!-- <a href=""><img class="guowuImg" src="../../assets/img/mediaCoverage/guowu1.jpg" alt="" title="恒安康泰医疗" /></a> -->
        <h3 class="stateNewsRighth3">
          <a href="" title="名医出诊">名医出诊</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[1]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
        <h3 class="stateNewsRighth3">
          <a href="" title="健康养生">健康养生</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[2]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
        <h2 class="stateNewsRighth3">恒安图文</h2>
        <hr class="stateNewsRight_h3_hr" />
        <a href=""><img class="guowuImg" src="../../assets/img/mediaCoverage/guowu2.jpg" alt="" title="恒安康泰医疗" /></a>
        <h3 class="stateNewsRighth3">
          <a href="" title="健康资讯">健康资讯</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[3]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
      </div>
    </div>
    <vfooter></vfooter>
    <GoTop />
  </div>
</template>
<script>
// import banner from "../../components/banner.vue";
import vheader from "../../components/vheader.vue";
import vfooter from "../../components/vfooter.vue";
import GoTop from "@/components/GoTop.vue";
export default {
  name: 'newscl5',
  data() {
    return {
      navName: '新闻详情',
      navUrl: 'newscl5',
      navId: 5,
      rilist1: [
        [
          { listit: "2020年全国居民健康素养水平达23.15%", lishref: "#" },
          { listit: "癌症治疗新希望！我国首个自主研发...", lishref: "#" },
          { listit: "国家卫健委出台人感染动物源性流感...", lishref: "#" },
          { listit: "国家医保局发文，明确推进门诊费用...", lishref: "#" },
          { listit: "国家卫健委：加强儿童中医药服务 推...", lishref: "#" },
          { listit: "这家西南的医院用高品质医疗服务，建...", lishref: "#" },
          { listit: "“十四五”残疾人康复服务蓝图绘就", lishref: "#" },
          { listit: "《2021医生互联网诊疗行为洞察报告》...", lishref: "#" },
          { listit: "六大方面布局！国办发文推动公立医院...", lishref: "#" },
          { listit: "七大城市用户调研指出：智慧医疗未来...", lishref: "#" },
        ],
        [
          { listit: "中国人民解放军总医院院士合集（部分）", lishref: "#" },
          { listit: "U医公益行启动，让泌尿疾病患者在基层...", lishref: "#" },
          { listit: "第十八期“院士+”西部儿科医师培训班座...", lishref: "#" },
          { listit: "福建首例机器人无腹部切口大肠癌手术在...", lishref: "#" },
          { listit: "多位专家赴湖北十堰参加燃气爆炸紧急医...", lishref: "#" },
          { listit: "张楠：阿尔茨海默病患者护理更需专业指导", lishref: "#" },
          { listit: "第一届智慧医疗与医学人文高峰论坛暨医...", lishref: "#" },
          { listit: "提醒身边长痣的朋友，出现以下五种症状...", lishref: "#" },
          { listit: "“疫”路同行的夫妻档", lishref: "#" },
          { listit: "与北京社团合作，中国西部城市乌兰察布...", lishref: "#" },
        ],
        [
          { listit: "水培蔬菜社交网络走红 专家：较土培更...", lishref: "#" },
          { listit: "山药的做法 山药的功效与作用", lishref: "#" },
          { listit: "发青的鸡蛋黄致癌？ 变绿部分无毒，可...", lishref: "#" },
          { listit: "经常失眠，可能是体内缺什么？3种营养...", lishref: "#" },
          { listit: "你知道健康吃饭的黄金标准吗？", lishref: "#" },
          { listit: "5大习惯能『防癌』，很多人都忽视了", lishref: "#" },
          { listit: "每餐4-6小时的间隔期最符合人体消化需...", lishref: "#" },
          { listit: "晨起一杯水别这么喝，风险太大...教你...", lishref: "#" },
          { listit: "心脏有问题，为什么医生还建议让我多运...", lishref: "#" },
          { listit: "缺铁性贫血，补就完事？补血食物别踩坑", lishref: "#" },
        ],
        [
          { listit: "疾控专家邵一鸣对我国目前疫苗功效给出解读", lishref: "#" },
          {
            listit: "馒头、米饭哪个更容易升血糖？一对比，3餐...",
            lishref: "#",
          },
          { listit: "手抖也能通过外科手术治愈啦", lishref: "#" },
          { listit: "身体血糖高 多喝水好还是少喝水好？看完...", lishref: "#" },
          { listit: "练起来 打太极拳能减“游泳圈”", lishref: "#" },
          { listit: "【科学就医我行动】陈勇皮科问答 | 10.皮...", lishref: "#" },
          { listit: "维生素C有什么功效和副作用？", lishref: "#" },
          {
            listit: "“提高免疫力”，被过度夸大化，免疫平衡比“...",
            lishref: "#",
          },
          { listit: "胃癌如何选择最合适疗方案？CA最新刊文指...", lishref: "#" },
          { listit: "九价HPV疫苗为何“一针难求”", lishref: "#" },
        ],
      ],
    };
  },
  components: {
    // banner,
    vheader,
    vfooter,
    GoTop,
  },
};
</script>
<style lang="less">
@media screen and (min-width:750px) {
  .banner-box {
    .banner-img {
      height: 280Px;
      background: url(../../assets/img/banner/banner01.jpg) no-repeat center top / 100%;
    }

    .banner-nav {
      background-color: #f6f8fb;

      .banner-nav-content {
        width: 1200Px;
        margin: auto;

        .el-breadcrumb {
          line-height: 70Px;
        }

        .el-breadcrumb__inner.is-link {
          font-weight: unset;
          color: #333;
          cursor: pointer;

          &:hover {
            color: #093C6C;
            text-decoration: underline;
          }
        }

        span {
          display: flex;
          align-items: center;

          .el-icon-location {
            color: #093C6C;
            font-size: 20Px;
            margin-right: 12Px;
          }

          float: left;
          color:#5a5a5a;
        }
      }
    }
  }
}

@media screen and (max-width:750px) {
  .banner-box {
    .banner-img {
      height: 110px;
      background: url(../../assets/img/banner/banner01.jpg) no-repeat center top / 200%;
    }

    .banner-nav {
      background-color: #f6f8fb;

      .banner-nav-content {
        width: 95%;
        margin: auto;

        .el-breadcrumb {
          line-height: 35px;
        }

        .el-breadcrumb__inner.is-link {
          font-weight: unset;
          color: #333;
          cursor: pointer;

          &:hover {
            color: #093C6C;
            text-decoration: underline;
          }
        }

        span {
          display: flex;
          align-items: center;

          .el-icon-location {
            color: #093C6C;
            font-size: 19px;
            margin-right: 2px;
          }

          float: left;
          color:#5a5a5a;
        }
      }
    }
  }
}
</style>